import { SearchId } from '@bbx/common/api/dtos/search-id'
import { SiteMutationLayout } from '@bbx/common/components/layouts/SiteMutationLayout'
import { AutoMotorStartpagesUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { StoryblokAutoMotorStartpage } from '@bbx/common/types/storyblok/StoryblokStartpage'
import { AutoMotorAdTypeCounters, getAutoMotorAdTypeCounters, getSearchResult } from '@bbx/search-journey/common/api/searchApiClient'
import { NavigatorId } from '@bbx/search-journey/common/Navigators'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { StartpageSeoMetaTags } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/StartpageSeoMetaTags'
import { AutoMotorStartPageContainer } from '@bbx/search-journey/sub-domains/search/components/verticals/auto-motor/start-pages/AutoMotorStartPageContainer'
import { removeAdsAndUnneededNavigators } from '@bbx/search-journey/sub-domains/search/lib/removeAdsAndUnneededNavigators'
import { ISbStoryData } from '@storyblok/react'
import { getMetaTagInfo, SeoPageName } from '@wh/common/chapter/api/tagging/metaTagApiClient'
import { MetaTagInfo } from '@wh/common/chapter/api/tagging/metaTagCache'
import { ErrorPage } from '@wh/common/chapter/components/ErrorPage/ErrorPage'
import type { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { usePageViewForPageWithHardcodedTaggingData } from '@wh/common/chapter/lib/tagging/usePageViewForPageWithHardcodedTaggingData'
import { OptionalPageOptions } from '@wh/common/chapter/types/nextJS'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { getStoryWithOptionalPreview } from '@wh/common/cms/api/storyblokApiClient'
import { GetStaticProps, GetStaticPropsContext, NextPage } from 'next'
import React, { Fragment } from 'react'

export interface StartPageProps {
    initialSearchResult: SearchResult
    initialCounters: AutoMotorAdTypeCounters
    metaTagInfo: MetaTagInfo
    story: ISbStoryData<StoryblokAutoMotorStartpage> | null
}

const SEO_PAGE_NAME = 'car'

const AutoMotorStartPage: NextPage<StartPageProps> & { Layout: typeof Layout } & { hasStaticProps: boolean } & OptionalPageOptions = ({
    initialSearchResult,
    initialCounters,
    metaTagInfo,
    story,
}) => {
    usePageViewForPageWithHardcodedTaggingData('vertical_home', {
        tmsData: {
            vertical_id: '3',
            vertical: 'Auto+%26+Motor',
        },
    })

    if (!initialSearchResult) {
        return <ErrorPage statusCode={500} />
    }

    return (
        <Fragment>
            <AutoMotorStartpagesUserZoom />
            <StartpageSeoMetaTags
                verticalId="MOTOR"
                metaTagInfo={metaTagInfo}
                seoPageName={SEO_PAGE_NAME}
                adTypeCounters={initialCounters}
            />
            <AutoMotorStartPageContainer initialSearchResult={initialSearchResult} initialCounters={initialCounters} story={story} />
        </Fragment>
    )
}

AutoMotorStartPage.Layout = SiteMutationLayout
AutoMotorStartPage.hasStaticProps = true
AutoMotorStartPage.verticalLinksType = 'sticky'

export const getStaticProps: GetStaticProps<StartPageProps> = async (context) => {
    return await getStaticPropsStartPages(SearchId.Cars, SEO_PAGE_NAME, context, '174839168')
}

export const getStaticPropsStartPages = async (
    searchId: SearchId,
    pageName: SeoPageName,
    context: GetStaticPropsContext,
    storyblokIdentifier: string,
) => {
    const initialSearchResultPromise = getSearchResult(verticalIdMap.MOTOR, searchId)
    const initialCountersPromise = getAutoMotorAdTypeCounters()
    const metaTagInfoPromise = getMetaTagInfo(pageName)

    const storyWrapperPromise = getStoryWithOptionalPreview<StoryblokAutoMotorStartpage>(storyblokIdentifier, context.previewData)

    let revalidate = 3600 // revalidate once per hour

    const [initialSearchResult, initialCounters, metaTagInfo, storyWrapper] = await Promise.all([
        initialSearchResultPromise,
        initialCountersPromise,
        metaTagInfoPromise,
        storyWrapperPromise?.catch((error) => {
            console.error('error fetching real-estate startpage data from storyblok', error)
            // if there is an error with the storyblok api request, next.js should try to rebuild the site in 3min
            revalidate = 180
        }),
    ])

    const filteredSearchResult = filterSearchResult(initialSearchResult)

    return {
        props: {
            initialSearchResult: filteredSearchResult,
            initialCounters: initialCounters,
            metaTagInfo: metaTagInfo,
            story: storyWrapper?.story ?? null,
        },
        revalidate: revalidate,
    }
}

/* strips some data - adverts and unneeded navigators - decreases the size of the static html+json. */
const filterSearchResult = (searchResult: SearchResult): SearchResult => {
    const navigatorIdsUsedOnStartPages: NavigatorId[] = [
        'make',
        'model',
        'keyword',
        'type',
        'mileage',
        'yearmodel',
        'price',
        'MOTORCONDITION',
    ]

    return removeAdsAndUnneededNavigators(searchResult, navigatorIdsUsedOnStartPages)
}

export default AutoMotorStartPage
